
import request, {uploadFile} from "@/utils/request";
import { Plus } from "@element-plus/icons-vue";
import { defineComponent } from "vue-demi";
import {UploadFile} from "element-plus";
import {nextTick} from "vue";

export default defineComponent({
  components: {
    Plus,
  },
  props: {
    imgUrl: {
      type: String,
    },
    isFile: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    uploadUrl:{
      type: String,
      default: () => {
        return '';
      },
    }
  },
  emits: ["backData",'backStart','update:imgUrl'],
  setup(prop, ctx) {
    return {
      onFlieChange,
    };

    function onFlieChange(upFile: UploadFile) {
      console.log(upFile);
      if (upFile.status == "ready") {
        ctx.emit('backStart');

        uploadFile(upFile.raw!,(d:any)=>{

          ctx.emit('update:imgUrl',d.content);
          ctx.emit("backData", d.content);
        },prop.uploadUrl);

      }
    }
    
  },
});
