import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a1e6d59"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = { style: {"display":"flex"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_search_form = _resolveComponent("search-form")!
  const _component_circle_plus = _resolveComponent("circle-plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_TablePagination = _resolveComponent("TablePagination")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_AvatarUploader = _resolveComponent("AvatarUploader")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_el_image = _resolveComponent("el-image")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_search_form, {
      "form-data": _ctx.searchForm,
      handleQuery: _ctx.searchClick
    }, {
      content: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "审核状态:",
          prop: "verifyStatus"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_radio_group, {
              modelValue: _ctx.searchForm.verifyStatus,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchForm.verifyStatus) = $event)),
              onChange: _ctx.searchClick
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.verifyList, (i) => {
                  return (_openBlock(), _createBlock(_component_el_radio, {
                    key: i.value,
                    label: i.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(i.key), 1)
                    ]),
                    _: 2
                  }, 1032, ["label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "拍卖类型:",
          prop: "ructionType",
          style: {"margin-left":"10px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_radio_group, {
              modelValue: _ctx.searchForm.ructionType,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchForm.ructionType) = $event)),
              onChange: _ctx.searchClick
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeList, (i) => {
                  return (_openBlock(), _createBlock(_component_el_radio, {
                    key: i.value,
                    label: i.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(i.key), 1)
                    ]),
                    _: 2
                  }, 1032, ["label"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "管理公司",
          prop: "managementCompany"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.searchForm.managementCompany,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchForm.managementCompany) = $event)),
              placeholder: "管理公司",
              onChange: _ctx.searchClick
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyList, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.value,
                    label: item.key,
                    value: item.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "onChange"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["form-data", "handleQuery"]),
    _createVNode(_component_TablePagination, {
      ref: "table",
      "list-url": "/admin/query/ructionList",
      height: _ctx.tableHeight,
      "list-key": 'ructionList',
      "other-para": _ctx.searchForm,
      border: true,
      "column-titles": [
      '货物名称',
      '开始时间',
      '结束时间',
      '拍卖价格',
      '当前价格',
      '装货地-卸货地',
      '拍卖类型',
      '审核状态',

      '拍卖状态',
      '中标人',
    ],
      props: [
      'freightName',
      'ructionStartTime',
      'ructionEndTime',
      'ructionStartPrice',
      'ructionCurrentPrice',
      'loadingDock',
      'ructionType',
      'verifyStatus',

      'ructionStatus',
      'userName'
    ],
      "custom-column-slots": {freightType:'freightType',settlementType:'settlementType',
        ructionType:'ructionType',ructionStatus:'ructionStatus',verifyStatus:'verifyStatus',
        loadingDock:'loadingDock',userName:'userName'
        },
      "operation-width": 360,
      "show-operation": true,
      "custom-column-width": {ructionStartTime:180,ructionEndTime:180}
    }, {
      header: _withCtx(() => [
        (_ctx.isSuper)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              type: "primary",
              onClick: _ctx.addClick
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_circle_plus)
                  ]),
                  _: 1
                }),
                _createTextVNode("新增")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      moreAction: _withCtx((d) => [
        (_ctx.isSuper)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              type: "primary",
              link: "",
              onClick: ($event: any) => (_ctx.editClick(d.val))
            }, {
              default: _withCtx(() => [
                _createTextVNode("编辑")
              ]),
              _: 2
            }, 1032, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.isSuper)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 1,
              type: "primary",
              link: "",
              onClick: ($event: any) => (_ctx.msgClick(d.val))
            }, {
              default: _withCtx(() => [
                _createTextVNode("短信通知")
              ]),
              _: 2
            }, 1032, ["onClick"]))
          : _createCommentVNode("", true),
        (d.val.ructionType=='low_price')
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 2,
              type: "primary",
              link: "",
              onClick: ($event: any) => (_ctx.choosePreson(d.val))
            }, {
              default: _withCtx(() => [
                _createTextVNode("选择中标人")
              ]),
              _: 2
            }, 1032, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_button, {
          type: "primary",
          link: "",
          onClick: ($event: any) => (_ctx.lookPreson(d.val))
        }, {
          default: _withCtx(() => [
            _createTextVNode("查看出价人")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        (_ctx.isSuper)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 3,
              type: "danger",
              link: "",
              onClick: ($event: any) => (_ctx.closePaimai(d.val))
            }, {
              default: _withCtx(() => [
                _createTextVNode("结束")
              ]),
              _: 2
            }, 1032, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      verifyStatus: _withCtx((d) => [
        _createTextVNode(_toDisplayString(_ctx.verifyList.find(e=>e.value===d.val)?.key), 1)
      ]),
      freightType: _withCtx((d) => [
        _createTextVNode(_toDisplayString(_ctx.huowuList.find(e=>e.value===d.val)?.key), 1)
      ]),
      settlementType: _withCtx((d) => [
        _createTextVNode(_toDisplayString(_ctx.jiesuanList.find(e=>e.value===d.val)?.key), 1)
      ]),
      ructionType: _withCtx((d) => [
        _createTextVNode(_toDisplayString(_ctx.typeList.find(e=>e.value===d.val)?.key), 1)
      ]),
      ructionStatus: _withCtx((d) => [
        _createTextVNode(_toDisplayString(_ctx.ructionStatusList.find(e=>e.value===d.val)?.key), 1)
      ]),
      loadingDock: _withCtx((d) => [
        _createTextVNode(_toDisplayString(d.val+'-'+d.row.dischargeJetties), 1)
      ]),
      userName: _withCtx((d) => [
        _createVNode(_component_el_button, {
          type: "primary",
          link: "",
          onClick: ($event: any) => (_ctx.userDetail(d.row))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(d.row.userName), 1)
          ]),
          _: 2
        }, 1032, ["onClick"])
      ]),
      _: 1
    }, 8, ["height", "other-para"]),
    _createVNode(_component_Dialog, {
      show: _ctx.showEdit,
      "onUpdate:show": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.showEdit) = $event)),
      title: _ctx.title,
      confirm: _ctx.confirmEdit,
      width: "80%"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: _ctx.form,
          "label-width": "120px",
          ref: "eform",
          rules: _ctx.rules
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "开始时间",
                      prop: "ructionStartTime"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.form.ructionStartTime,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.ructionStartTime) = $event)),
                          placeholder: "开始时间",
                          type: "datetime",
                          "disabled-date": _ctx.diableDate,
                          "value-format": "YYYY-MM-DD HH:mm:ss"
                        }, null, 8, ["modelValue", "disabled-date"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "结束时间",
                      prop: "ructionEndTime"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.form.ructionEndTime,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.ructionEndTime) = $event)),
                          placeholder: "结束时间",
                          type: "datetime",
                          "disabled-date": _ctx.diableDate,
                          "value-format": "YYYY-MM-DD HH:mm:ss"
                        }, null, 8, ["modelValue", "disabled-date"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "是否多轮出价",
                      prop: "isRuctionOffer"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_switch, {
                          modelValue: _ctx.form.isRuctionOffer,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.isRuctionOffer) = $event)),
                          onChange: _ctx.isOfferChange
                        }, null, 8, ["modelValue", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.form.isRuctionOffer)
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 0,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "一轮报价时间",
                          prop: "ructionOfferTime"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_date_picker, {
                              modelValue: _ctx.form.ructionOfferTime,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.ructionOfferTime) = $event)),
                              placeholder: "出价时间",
                              type: "datetime",
                              "value-format": "YYYY-MM-DD HH:mm:ss"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "管理公司",
                      prop: "managementCompany"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.form.managementCompany,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.managementCompany) = $event)),
                          placeholder: "管理公司"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyList, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.key,
                                value: item.value
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "货物名称",
                      prop: "freightName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.form.freightName,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.freightName) = $event)),
                          placeholder: "货物名称"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (!_ctx.form.isRuctionOffer)
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 1,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "起拍价",
                          prop: "ructionStartPrice"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              type: "number",
                              modelValue: _ctx.form.ructionStartPrice,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.ructionStartPrice) = $event)),
                              modelModifiers: { number: true },
                              placeholder: "拍卖价格"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "最低降价",
                      prop: "minimumPriceReduction"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: "number",
                          modelValue: _ctx.form.minimumPriceReduction,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.minimumPriceReduction) = $event)),
                          modelModifiers: { number: true },
                          placeholder: "最低降价"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "货物价格",
                      prop: "price"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: "number",
                          modelValue: _ctx.form.price,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.price) = $event)),
                          modelModifiers: { number: true },
                          placeholder: "价格"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "货物价值",
                      prop: "value"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: "number",
                          modelValue: _ctx.form.value,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.value) = $event)),
                          modelModifiers: { number: true },
                          placeholder: "价值"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "保证金",
                      prop: "bond"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: "number",
                          modelValue: _ctx.form.bond,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.bond) = $event)),
                          modelModifiers: { number: true },
                          placeholder: "保证金"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "滞期费用",
                      prop: "bond"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: "number",
                          modelValue: _ctx.form.demurrageFees,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.demurrageFees) = $event)),
                          modelModifiers: { number: true },
                          placeholder: "滞期费用"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "装载量",
                      prop: "loadingCapacity"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: "number",
                          modelValue: _ctx.form.loadingCapacity,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.loadingCapacity) = $event)),
                          modelModifiers: { number: true },
                          placeholder: "装载量"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "装货时间",
                      prop: "loadingTime"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.form.loadingTime,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.form.loadingTime) = $event)),
                          placeholder: "装货时间",
                          type: "datetime",
                          "disabled-date": _ctx.diableDate,
                          "value-format": "YYYY-MM-DD HH:mm:ss"
                        }, null, 8, ["modelValue", "disabled-date"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "卸货时间",
                      prop: "dischargeTime"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: _ctx.form.dischargeTime,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.dischargeTime) = $event)),
                          placeholder: "卸货时间",
                          type: "datetime",
                          "disabled-date": _ctx.diableDate,
                          "value-format": "YYYY-MM-DD HH:mm:ss"
                        }, null, 8, ["modelValue", "disabled-date"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "装货码头",
                      prop: "loadingDock"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.form.loadingDock,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.form.loadingDock) = $event)),
                          placeholder: "装货码头"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "卸货码头",
                      prop: "dischargeJetties"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.form.dischargeJetties,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.form.dischargeJetties) = $event)),
                          placeholder: "卸货码头"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "拍卖类型",
                      prop: "ructionType"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_radio_group, {
                          modelValue: _ctx.form.ructionType,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.form.ructionType) = $event)),
                          onChange: _ctx.ructionTypeChange
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeList, (i) => {
                              return (_openBlock(), _createBlock(_component_el_radio, {
                                key: i.value,
                                label: i.value
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(i.key), 1)
                                ]),
                                _: 2
                              }, 1032, ["label"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "审核状态",
                      prop: "verifyStatus"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_radio_group, {
                          modelValue: _ctx.form.verifyStatus,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.form.verifyStatus) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.verifyList, (i) => {
                              return (_openBlock(), _createBlock(_component_el_radio, {
                                key: i.value,
                                label: i.value
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(i.key), 1)
                                ]),
                                _: 2
                              }, 1032, ["label"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "文件地址",
                      prop: "ApkUrl"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createVNode(_component_AvatarUploader, {
                            isFile: true,
                            onBackData: _ctx.backUrl,
                            onBackStart: _cache[22] || (_cache[22] = ($event: any) => {_ctx.loading=true;}),
                            style: {"width":"38px","height":"38px","margin-right":"10px"}
                          }, null, 8, ["onBackData"]),
                          _createVNode(_component_el_input, {
                            style: {"flex":"1"},
                            disabled: "",
                            modelValue: _ctx.form.file,
                            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.form.file) = $event))
                          }, null, 8, ["modelValue"])
                        ])), [
                          [_directive_loading, _ctx.loading]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "运输及安全责任" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: "textarea",
                          modelValue: _ctx.form.transportationSafetyAndResponsibility,
                          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.form.transportationSafetyAndResponsibility) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "船型及服务要求" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: "textarea",
                          modelValue: _ctx.form.shipTypeAndServiceRequirements,
                          "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.form.shipTypeAndServiceRequirements) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["show", "title", "confirm"]),
    _createVNode(_component_Dialog, {
      show: _ctx.showPersonList,
      "onUpdate:show": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.showPersonList) = $event)),
      title: _ctx.title,
      width: "80%"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TablePagination, {
          ref: "table1",
          style: {"margin-bottom":"10px"},
          "init-data": _ctx.bidderList,
          "show-pagination": false,
          border: true,
          "column-titles": 
          _ctx.isOffer?[
              '用户名',
      '真实姓名',
      '水陆经营许可证',
      '水陆运输代理证',
      '营业执照',
      '出价',
          ]:
          [
      '用户名',
      '真实姓名',
      '水陆经营许可证',
      '水陆运输代理证',
      '营业执照',
      '报价',
      '报价时间',
      '是否中标'
    ],
          props: 
          _ctx.isOffer?[
              'userName',
      'trueName',
      'landAndWaterBusinessLicense',
      'landAndWaterTransportationAgencyCertificate',
      'businessLicense',
      'offerCurrentPrice'
          ]:
          [
      'userName',
      'trueName',
      'landAndWaterBusinessLicense',
      'landAndWaterTransportationAgencyCertificate',
      'businessLicense',
      'bidderCurrentPrice',
      'bidderTime',
      'isSuccessfulBidder'
    ],
          "show-operation": _ctx.isSuper&&!_ctx.isOffer,
          "custom-column-slots": {
            isSuccessfulBidder:'isSuccessfulBidder',
            landAndWaterBusinessLicense:'landAndWaterBusinessLicense',
            landAndWaterTransportationAgencyCertificate:'landAndWaterTransportationAgencyCertificate',
            businessLicense:'businessLicense'
          },
          "custom-column-width": {bidderTime:180}
        }, {
          moreAction: _withCtx((d) => [
            _createVNode(_component_el_button, {
              type: "primary",
              link: "",
              onClick: ($event: any) => (_ctx.chooseCLick(d.val))
            }, {
              default: _withCtx(() => [
                _createTextVNode("选择")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          isSuccessfulBidder: _withCtx((d) => [
            _createElementVNode("div", {
              style: _normalizeStyle(d.val=='1'?'color:green;':'color:black')
            }, _toDisplayString(d.val=='1'?'中标':'未中标'), 5)
          ]),
          landAndWaterBusinessLicense: _withCtx((d) => [
            (d.val)
              ? (_openBlock(), _createBlock(_component_el_image, {
                  key: 0,
                  style: {"height":"60px"},
                  src: d.val,
                  "preview-src-list": [d.val],
                  "preview-teleported": true
                }, null, 8, ["src", "preview-src-list"]))
              : _createCommentVNode("", true)
          ]),
          landAndWaterTransportationAgencyCertificate: _withCtx((d) => [
            (d.val)
              ? (_openBlock(), _createBlock(_component_el_image, {
                  key: 0,
                  style: {"height":"60px"},
                  src: d.val,
                  "preview-src-list": [d.val],
                  "preview-teleported": true
                }, null, 8, ["src", "preview-src-list"]))
              : _createCommentVNode("", true)
          ]),
          businessLicense: _withCtx((d) => [
            (d.val)
              ? (_openBlock(), _createBlock(_component_el_image, {
                  key: 0,
                  style: {"height":"60px"},
                  src: d.val,
                  "preview-src-list": [d.val],
                  "preview-teleported": true
                }, null, 8, ["src", "preview-src-list"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["init-data", "column-titles", "props", "show-operation"])
      ]),
      _: 1
    }, 8, ["show", "title"]),
    _createVNode(_component_Dialog, {
      show: _ctx.showUser,
      "onUpdate:show": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.showUser) = $event)),
      title: "详情",
      width: "80%"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: _ctx.form,
          "label-width": "100px",
          ref: "eform",
          rules: _ctx.rules
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "用户名称",
                      prop: "userName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          disabled: true,
                          modelValue: _ctx.form.userName,
                          "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.form.userName) = $event)),
                          placeholder: "用户名称"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "真实名称",
                      prop: "trueName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          disabled: true,
                          modelValue: _ctx.form.trueName,
                          "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.form.trueName) = $event)),
                          placeholder: "真实名称"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "手机号",
                      prop: "phone"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          disabled: true,
                          modelValue: _ctx.form.phone,
                          "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.form.phone) = $event)),
                          placeholder: "手机号"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "用户类型",
                      prop: "userType"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          disabled: true,
                          modelValue: _ctx.form.userType,
                          "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.form.userType) = $event)),
                          placeholder: "用户类型"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userTypeList, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.key,
                                value: item.value
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "身份证(前)" }, {
                      default: _withCtx(() => [
                        (_ctx.form.identityCardFront)
                          ? (_openBlock(), _createBlock(_component_el_image, {
                              key: 0,
                              style: {"height":"100px"},
                              src: _ctx.form.identityCardFront,
                              "preview-src-list": [_ctx.form.identityCardFront]
                            }, null, 8, ["src", "preview-src-list"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "身份证(后)" }, {
                      default: _withCtx(() => [
                        (_ctx.form.identityCardBack)
                          ? (_openBlock(), _createBlock(_component_el_image, {
                              key: 0,
                              style: {"height":"100px"},
                              src: _ctx.form.identityCardBack,
                              "preview-src-list": [_ctx.form.identityCardBack]
                            }, null, 8, ["src", "preview-src-list"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "经营许可证" }, {
                      default: _withCtx(() => [
                        (_ctx.form.landAndWaterBusinessLicense)
                          ? (_openBlock(), _createBlock(_component_el_image, {
                              key: 0,
                              style: {"height":"100px"},
                              src: _ctx.form.landAndWaterBusinessLicense,
                              "preview-src-list": [_ctx.form.landAndWaterBusinessLicense]
                            }, null, 8, ["src", "preview-src-list"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "运输代理证" }, {
                      default: _withCtx(() => [
                        (_ctx.form.landAndWaterTransportationAgencyCertificate)
                          ? (_openBlock(), _createBlock(_component_el_image, {
                              key: 0,
                              style: {"height":"100px"},
                              src: _ctx.form.landAndWaterTransportationAgencyCertificate,
                              "preview-src-list": [_ctx.form.landAndWaterTransportationAgencyCertificate]
                            }, null, 8, ["src", "preview-src-list"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "营业执照" }, {
                      default: _withCtx(() => [
                        (_ctx.form.businessLicense)
                          ? (_openBlock(), _createBlock(_component_el_image, {
                              key: 0,
                              style: {"height":"100px"},
                              src: _ctx.form.businessLicense,
                              "preview-src-list": [_ctx.form.businessLicense]
                            }, null, 8, ["src", "preview-src-list"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "备注" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          disabled: true,
                          type: "textarea",
                          modelValue: _ctx.form.about,
                          "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.form.about) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}