import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createBlock(_component_el_upload, {
    "on-change": _ctx.onFlieChange,
    class: "avatar-uploader",
    "auto-upload": false,
    "show-file-list": false,
    action: "/api/upload/admin/OssUploadPhoto"
  }, {
    default: _withCtx(() => [
      (_ctx.isFile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_el_icon, { class: "avatar-uploader-icon" }, {
              default: _withCtx(() => [
                _createVNode(_component_Plus)
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.imgUrl)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.imgUrl,
                  class: "avatar"
                }, null, 8, _hoisted_3))
              : (_openBlock(), _createBlock(_component_el_icon, {
                  key: 1,
                  class: "avatar-uploader-icon"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Plus)
                  ]),
                  _: 1
                }))
          ]))
    ]),
    _: 1
  }, 8, ["on-change"]))
}